
// Use Material Theme
// Updating available theme color settings
@forward "@material/theme" with (
    $primary        : #0a3a5a, // BG
    $on-primary     : #ffffff, // Text
    $secondary      : #bb29bb, // BG
    $on-secondary   : #ffffff, // Text
    $background     : #e7e8e7, // BG
    $surface        : #ffffff, // BG
    $on-surface     : #333333, // Text
    $error          : #b00020, // BG
    $on-error       : #ffffff  // Text
);

$success: #4caf50;
$warning: #ff9800;
$info: #2196f3;

hr {
    border-width: 1px 0 0;
    border-top-style: solid;
    border-top-color: #e5e5e5;
}