.ga-list__outlined {
    border: 1px solid #e5e5e5;
}

.styled-ol {
  list-style: none;
  margin-left: 1rem;

  & > li {
    counter-increment: steps;
    position: relative;
    margin-bottom: 3.125rem;
  }

  & > li:last-child {
    margin-bottom: 0rem;
  }

  & > li:before {
    content: counter(steps);
    background: #0a3a5a;
    border-radius: 50%;
    position: absolute;
    left: -25px;
    top: 0;
    height: 1.5em;
    width: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.3;
    color: #fff;
  }
}

.styled-ul {
  list-style: disc;   
  margin-left: 2.188rem;
}