@use "theme";
@use "typography";

.nav-bar-logos {
  display: flex;
  margin-top: 4px;
}

.vote411-logo {
  margin-right: 10px;
}

.nav-bar-link {
  color: theme.$primary;
  font-family: typography.$font-family;
  font-weight: 500; // medium
  font-size: 16px;
  line-height: 16px;
  text-decoration: none;
}

.ga-primary-nav-bar {
  background-color: #07263b;

  .mdc-button {
    color: theme.$surface;
    font: typography.$font-family;
    opacity: .87;
    font-size: 14px;
    text-decoration: none solid;
    line-height: 24px;
    text-transform: uppercase;

    &:hover {
      opacity: 1,
    }
  }
}
