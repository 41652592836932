@use "theme";
@use "typography";

.ga-alert {
    display: flex;
    padding: 6px 16px;
    font-size: 0.875rem;
    font: typography.$font-family;
    font-weight: 400;
    line-height: 1.43;
    border-radius: 4px;
    letter-spacing: 0.01071em;
    background-color: transparent;
    font-weight: 500;

    &.ga-alert__error {
        color: rgb(97, 26, 21);
        background-color: rgb(253, 236, 234);

        .ga-alert--icon {
            color: #f44336;
        }
    }

    &.ga-alert__warning {
        color: rgb(102, 60, 0);
        background-color: rgb(255, 244, 229);

        .ga-alert--icon {
            color: #ff9800;
        }
    }

    &.ga-alert__info {
        color: rgb(13, 60, 97);
        background-color: rgb(232, 244, 253);

        .ga-alert--icon {
            color: #2196f3;
        }
    }

    &.ga-alert__success {
        color: rgb(30, 70, 32);
        background-color: rgb(237, 247, 237);

        .ga-alert--icon {
            color: #4caf50;
        }
    }

    .ga-alert--icon {
        display: flex;
        opacity: 0.9;
        padding: 7px 0;
        font-size: 22px;
        margin-right: 12px;
    }

    .ga-alert--message {
        padding: 8px 0;
    }
}