


// Material Typography 
// Update available settings for fonts
// At very least we want to set font-size and line-height
// Note:
// We can also set color and font-weight if they will
// be consistently applied throughout design,
// otherwise setting via html tag or css class in the HTML
// will be sufficient
@forward "@material/typography" with (
    $font-family: unquote("Public Sans, sans-serif"),
    $styles-headline1: (
        font-size: 35px,
        line-height: 40px,
        margin-bottom: .6rem
    ),
    $styles-headline2: (
        font-size: 25px,
        line-height: 32px
    ),
    $styles-headline3: (
        font-size: 21px,
        line-height: 28px
    ),
    $styles-headline4: (
        font-size: 18px,
        line-height: 24px
    ),
    $styles-headline5: (
        font-size: 16px,
        line-height: 20px
    ),
    $styles-headline6: (
        font-size: 14px,
        line-height: 24px
    ),
    $styles-subtitle1: (
        font-size: 18px,
        line-height: 24px
    ),
    $styles-subtitle2: (
        font-size: 16px,
        line-height: 20px
    ),
    $styles-body1: (
        font-size: 18px,
        line-height: 24px,
        margin-bottom: 2rem
    ),
    $styles-body2: (
        font-size: 16px,
        line-height: 20px
    ),
    $styles-overline: (
        font-size: 10px,
        line-height: 14px
    )
);

// One-Off custom sizes
.mdc-typography--headline1,
.mdc-typography--headline2,
.mdc-typography--headline3,
.mdc-typography--headline4,
.mdc-typography--headline5,
.mdc-typography--headline6 {
    &.ga-typography-large {
        font-size: 60px;
        line-height: 70px;
    }
}

a.mdc-top-app-bar__title {
    color: #fff;
    font-weight: bold;
    text-decoration: none;
}

.link-text {
    color: #0a3a5a;
}

a {
  color: #0a3a5a;
  font-weight: 600;
}