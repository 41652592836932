

.ga-p-top--none {
    padding-top: 0 !important;
}

.ga-p-top--xxx-small {
    padding-top: 0.125rem;
}

.ga-p-top--xx-small {
    padding-top: 0.25rem;
}

.ga-p-top--x-small {
    padding-top: 0.5rem;
}

.ga-p-top--small {
    padding-top: 0.75rem;
}

.ga-p-top--medium {
    padding-top: 1rem;
}

.ga-p-top--large {
    padding-top: 1.5rem;
}

.ga-p-top--x-large {
    padding-top: 2rem; 
}

.ga-p-top--xx-large {
    padding-top: 3rem; 
}

.ga-p-right--none {
    padding-right: 0 !important; 
}

.ga-p-right--xxx-small {
    padding-right: 0.125rem; 
}

.ga-p-right--xx-small {
    padding-right: 0.25rem; 
}

.ga-p-right--x-small {
    padding-right: 0.5rem; 
}

.ga-p-right--small {
    padding-right: 0.75rem; 
}

.ga-p-right--medium {
    padding-right: 1rem; 
}

.ga-p-right--large {
    padding-right: 1.5rem; 
}

.ga-p-right--x-large {
    padding-right: 2rem; 
}

.ga-p-right--xx-large {
    padding-right: 3rem; 
}

.ga-p-bottom--none {
    padding-bottom: 0 !important; 
}

.ga-p-bottom--xxx-small {
    padding-bottom: 0.125rem; 
}

.ga-p-bottom--xx-small {
    padding-bottom: 0.25rem; 
}

.ga-p-bottom--x-small {
    padding-bottom: 0.5rem; 
}

.ga-p-bottom--small {
    padding-bottom: 0.75rem; 
}

.ga-p-bottom--medium {
    padding-bottom: 1rem; 
}

.ga-p-bottom--large {
    padding-bottom: 1.5rem; 
}

.ga-p-bottom--x-large {
    padding-bottom: 2rem; 
}

.ga-p-bottom--xx-large {
    padding-bottom: 3rem; 
}

.ga-p-left--none {
    padding-left: 0 !important; 
}

.ga-p-left--xxx-small {
    padding-left: 0.125rem; 
}

.ga-p-left--xx-small {
    padding-left: 0.25rem; 
}

.ga-p-left--x-small {
    padding-left: 0.5rem; 
}

.ga-p-left--small {
    padding-left: 0.75rem; 
}

.ga-p-left--medium {
    padding-left: 1rem; 
}

.ga-p-left--large {
    padding-left: 1.5rem; 
}

.ga-p-left--x-large {
    padding-left: 2rem; 
}

.ga-p-left--xx-large {
    padding-left: 3rem; 
}

.ga-p-horizontal--none {
    padding-left: 0 !important;
    padding-right: 0 !important; 
}

.ga-p-horizontal--xxx-small {
    padding-left: 0.125rem;
    padding-right: 0.125rem; 
}

.ga-p-horizontal--xx-small {
    padding-left: 0.25rem;
    padding-right: 0.25rem; 
}

.ga-p-horizontal--x-small {
    padding-left: 0.5rem;
    padding-right: 0.5rem; 
}

.ga-p-horizontal--small {
    padding-left: 0.75rem;
    padding-right: 0.75rem; 
}

.ga-p-horizontal--medium {
    padding-left: 1rem;
    padding-right: 1rem; 
}

.ga-p-horizontal--large {
    padding-left: 1.5rem;
    padding-right: 1.5rem; 
}

.ga-p-horizontal--x-large {
    padding-left: 2rem;
    padding-right: 2rem;
}

.ga-p-horizontal--xx-large {
    padding-left: 3rem;
    padding-right: 3rem;
}

.ga-p-vertical--none {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.ga-p-vertical--xxx-small {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
}

.ga-p-vertical--xx-small {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.ga-p-vertical--x-small {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.ga-p-vertical--small {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.ga-p-vertical--medium {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.ga-p-vertical--large {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.ga-p-vertical--x-large {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.ga-p-vertical--xx-large {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.ga-p-around--none {
    padding: 0 !important;
}

.ga-p-around--xxx-small {
    padding: 0.125rem;
}

.ga-p-around--xx-small {
    padding: 0.25rem;
}

.ga-p-around--x-small {
    padding: 0.5rem;
}

.ga-p-around--small {
    padding: 0.75rem;
}

.ga-p-around--medium {
    padding: 1rem;
}

.ga-p-around--large {
    padding: 1.5rem; 
}

.ga-p-around--x-large {
    padding: 2rem;
}

.ga-p-around--xx-large {
    padding: 3rem;
}
