


.ga-m-top--none {
    margin-top: 0 !important;
}

.ga-m-top--xxx-small {
    margin-top: 0.125rem;
}

.ga-m-top--xx-small {
    margin-top: 0.25rem;
}

.ga-m-top--x-small {
    margin-top: 0.5rem;
}

.ga-m-top--small {
    margin-top: 0.75rem;
}

.ga-m-top--medium {
    margin-top: 1rem;
}

.ga-m-top--large {
    margin-top: 1.5rem;
}

.ga-m-top--x-large {
    margin-top: 2rem; 
}

.ga-m-top--xx-large {
    margin-top: 3rem; 
}

.ga-m-right--none {
    margin-right: 0 !important; 
}

.ga-m-right--xxx-small {
    margin-right: 0.125rem; 
}

.ga-m-right--xx-small {
    margin-right: 0.25rem; 
}

.ga-m-right--x-small {
    margin-right: 0.5rem; 
}

.ga-m-right--small {
    margin-right: 0.75rem; 
}

.ga-m-right--medium {
    margin-right: 1rem; 
}

.ga-m-right--large {
    margin-right: 1.5rem; 
}

.ga-m-right--x-large {
    margin-right: 2rem; 
}

.ga-m-right--xx-large {
    margin-right: 3rem; 
}

.ga-m-bottom--none {
    margin-bottom: 0 !important; 
}

.ga-m-bottom--xxx-small {
    margin-bottom: 0.125rem; 
}

.ga-m-bottom--xx-small {
    margin-bottom: 0.25rem; 
}

.ga-m-bottom--x-small {
    margin-bottom: 0.5rem; 
}

.ga-m-bottom--small {
    margin-bottom: 0.75rem; 
}

.ga-m-bottom--medium {
    margin-bottom: 1rem; 
}

.ga-m-bottom--large {
    margin-bottom: 1.5rem; 
}

.ga-m-bottom--x-large {
    margin-bottom: 2rem; 
}

.ga-m-bottom--xx-large {
    margin-bottom: 3rem; 
}

.ga-m-left--none {
    margin-left: 0 !important; 
}

.ga-m-left--xxx-small {
    margin-left: 0.125rem; 
}

.ga-m-left--xx-small {
    margin-left: 0.25rem; 
}

.ga-m-left--x-small {
    margin-left: 0.5rem; 
}

.ga-m-left--small {
    margin-left: 0.75rem; 
}

.ga-m-left--medium {
    margin-left: 1rem; 
}

.ga-m-left--large {
    margin-left: 1.5rem; 
}

.ga-m-left--x-large {
    margin-left: 2rem; 
}

.ga-m-left--xx-large {
    margin-left: 3rem; 
}

.ga-m-horizontal--none {
    margin-left: 0 !important;
    margin-right: 0 !important; 
}

.ga-m-horizontal--xxx-small {
    margin-left: 0.125rem;
    margin-right: 0.125rem; 
}

.ga-m-horizontal--xx-small {
    margin-left: 0.25rem;
    margin-right: 0.25rem; 
}

.ga-m-horizontal--x-small {
    margin-left: 0.5rem;
    margin-right: 0.5rem; 
}

.ga-m-horizontal--small {
    margin-left: 0.75rem;
    margin-right: 0.75rem; 
}

.ga-m-horizontal--medium {
    margin-left: 1rem;
    margin-right: 1rem; 
}

.ga-m-horizontal--large {
    margin-left: 1.5rem;
    margin-right: 1.5rem; 
}

.ga-m-horizontal--x-large {
    margin-left: 2rem;
    margin-right: 2rem;
}

.ga-m-horizontal--xx-large {
    margin-left: 3rem;
    margin-right: 3rem;
}

.ga-m-vertical--none {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.ga-m-vertical--xxx-small {
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
}

.ga-m-vertical--xx-small {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}

.ga-m-vertical--x-small {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.ga-m-vertical--small {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
}

.ga-m-vertical--medium {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.ga-m-vertical--large {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.ga-m-vertical--x-large {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.ga-m-vertical--xx-large {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.ga-m-around--none {
    margin: 0 !important;
}

.ga-m-around--xxx-small {
    margin: 0.125rem;
}

.ga-m-around--xx-small {
    margin: 0.25rem;
}

.ga-m-around--x-small {
    margin: 0.5rem;
}

.ga-m-around--small {
    margin: 0.75rem;
}

.ga-m-around--medium {
    margin: 1rem;
}

.ga-m-around--large {
    margin: 1.5rem; 
}

.ga-m-around--x-large {
    margin: 2rem;
}

.ga-m-around--xx-large {
    margin: 3rem;
}


// ===================================================================================================
// Custom Margin and padding
// ===================================================================================================
@each $prop, $abbrev in (margin: m, padding: p) {

    @for $index from 0 through 64 {
        $size: $index * 4;
        $length: #{$size}px;

        // ga-m-0, ga-m-4, ga-m-8 ... ga-m-256
        // ga-p-0, ga-p-4, ga-p-8 ... ga-p-256
        .ga-#{$abbrev}-#{$size} {
            #{$prop}: $length !important;
        }
    }
  
    @for $index from 0 through 64 {
        $size: $index * 4;
        $length: #{$size}px;

        // ga-mx-0, ga-mx-4, ga-mx-8 ... ga-mx-256
        // ga-px-0, ga-px-4, ga-px-8 ... ga-px-256
        .ga-#{$abbrev}x-#{$size} {
            #{$prop}-right: $length !important;
            #{$prop}-left: $length !important;
        }

        // ga-my-0, ga-my-4, ga-my-8 ... ga-my-256
        // ga-py-0, ga-py-4, ga-py-8 ... ga-py-256
        .ga-#{$abbrev}y-#{$size} {
            #{$prop}-top: $length !important;
            #{$prop}-bottom: $length !important;
        }
    }
  
    @for $index from 0 through 64 {
        $size: $index * 4;
        $length: #{$size}px;

        // ga-mt-0, ga-mt-4, ga-mt-8 ... ga-mt-256
        // ga-pt-0, ga-pt-4, ga-pt-8 ... ga-pt-256
        .ga-#{$abbrev}t-#{$size} {
            #{$prop}-top: $length !important;
        }

        // ga-mr-0, ga-mr-4, ga-mr-8 ... ga-mr-256
        // ga-pr-0, ga-pr-4, ga-pr-8 ... ga-pr-256
        .ga-#{$abbrev}r-#{$size} {
            #{$prop}-right: $length !important;
        }

        // ga-mb-0, ga-mb-4, ga-mb-8 ... ga-mb-256
        // ga-pb-0, ga-pb-4, ga-pb-8 ... ga-pb-256
        .ga-#{$abbrev}b-#{$size} {
            #{$prop}-bottom: $length !important;
        }

        // ga-ml-0, ga-ml-4, ga-ml-8 ... ga-ml-256
        // ga-pl-0, ga-pl-4, ga-pl-8 ... ga-pl-256
        .ga-#{$abbrev}l-#{$size} {
            #{$prop}-left: $length !important;
        }
    }
}