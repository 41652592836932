// ===================================================================================================
// Height and Width
// ===================================================================================================
@each $prop, $abbrev in (height: h, width: w) {

    @for $index from 0 through 180 {
        $size: $index * 4;
        $length: #{$size}px;
  
        // ga-h-0, ga-h-4, ga-h-8 ... ga-h-720
        // ga-w-0, ga-w-4, ga-w-8 ... ga-w-720
        .ga-#{$abbrev}-#{$size} {
            #{$prop}: $length !important;
            min-#{$prop}: $length !important;
            max-#{$prop}: $length !important;
        }
  
        // ga-min-h-0, ga-min-h-4, ga-min-h-8 ... ga-min-h-720
        // ga-min-w-0, ga-min-w-4, ga-min-w-8 ... ga-min-w-720
        .ga-min-#{$abbrev}-#{$size} {
            min-#{$prop}: $length !important;
        }
  
        // ga-max-h-0, ga-max-h-4, ga-max-h-8 ... ga-max-h-720
        // ga-max-w-0, ga-max-w-4, ga-max-w-8 ... ga-max-w-720
        .ga-max-#{$abbrev}-#{$size} {
            max-#{$prop}: $length !important;
        }
    }
  
    // Percentage
    @for $i from 0 through 20 {
        $i-p: 5 * $i;
        $size-p: 5% * $i;
  
        // ga-h-0-p, ga-h-5-p, ga-h-10-p ... ga-h-100-p
        // ga-w-0-p, ga-w-5-p, ga-w-10-p ... ga-w-100-p
        .ga-#{$abbrev}-#{$i-p}-p {
            #{$prop}: $size-p !important;
        }
    }
}

.full-height {
    height: 100%;
}
