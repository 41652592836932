// Establish Page Container
.ga-page {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	margin-bottom: 3rem;
	min-height: 100%;
	position: relative;
	width: 100%;

	// Use Gradient BG
	&.ga-page--bg-highlight {
		background-repeat: no repeat;
		background-image: linear-gradient(36deg, #0a3a5a, #b030b5);
	}
}

// Container with max-width typically within a page
.ga-container {
	margin: 0 auto;
	max-width: 1366px;
	width: 100%;

	// this might be redundant
	&.ga-content--align-center {
		-webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: center;
	}
}

// Custom box (card) variation  - might be removed
.ga-box {
	background-color: var(--mdc-theme-surface);
	color: var(--mdc-theme-on-surface);
	padding: 20px;
}

.full-width {
	width: 100vw;
	position: relative;
	left: 50%;
	right: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
}