.ga-sidebar-layout {
    padding-top: 0;
    padding-bottom: 0;

    .ga-sidebar-layout__sidebar {
        padding-bottom: var(--mdc-layout-grid-margin-desktop, 24px);
        padding-top: var(--mdc-layout-grid-margin-desktop, 24px);
        position: relative;
    
        .ga-sidebar-layout__sidebar-bg {
            background-color: #eef4f8;
            height: 100%;
            margin-left: -100%;
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            width: 100vw;
            z-index: -1;
        }

        .ga-sidebar-layout__sidebar-content {
            padding-right: 1rem;
        }
    }

    .ga-sidebar-layout__main {
        padding-bottom: var(--mdc-layout-grid-margin-desktop, 24px);
        padding-top: var(--mdc-layout-grid-margin-desktop, 24px);
    }

    .ga-sidebar__nav {
        list-style-type: none;

        li {
            margin: 40px 0 40px 30px;
        }

        li:first-child {
            margin-top: 0;
        }

        li a {
            text-decoration: none;
            color: #b1b5b8;
        }

        li a:hover {
            text-decoration: underline;
        }

        li.active {
            align-items: center;
            color: #333;
            display: flex;
            font-weight: 700;
            margin: 40px 0;
        }

        li.active::before {
            display: block;
            content: '';
            background-image: url('../images/nav--active.svg');
            background-size: 20px 20px;
            height: 20px;
            width: 20px;
            margin-right: 10px;
        }
    }
}
