.flex {
    display: flex;
}

.mdc-top-app-bar--fixed-adjust {
    padding-top: 128px;
}

.ga-banner {
    background-color: #eef4f8;
}

.ga-footer {
    font-size: 1.125rem;
    padding: 0px 18px 8rem;
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;

    .ga-footer--logo {
        margin-bottom: 1rem;
    }

    .ga-footer--copyright {
        font-size: .875rem;
    }

    a {
        color: #0a3a5a;
    }

    @media screen and (min-width: 45em) {
        justify-content: space-between;
        flex-wrap: nowrap;

        .ga-footer--logo {
            margin-bottom: 0;
        }
    }
}

.ga-candidate-response {
    height: 100%;

    .main {
        height: 100%;
        padding-top: 56px;

        @media screen and (min-width: 38em) {
            padding-top: 64px;
        }
    }
}