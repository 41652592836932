

@use "@material/button";

// Override Material button theming for standard 'mdc-button'
// using a custom class 'ga-button' to signal this application
.mdc-button {
    min-width: 150px;

    &.ga-button {
        --mdc-theme-primary: #bb29bb; // Changing 'primary' bg
        --mdc-theme-on-primary: #ffffff; // Changing 'on-primary' text
        &.mdc-button--raised {
            border-radius: 20px; // Updating border radius for raised button
            & .mdc-button__ripple {
                border-radius: 20px; // have to update ripple container
            }
        }
    }
}