
*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
em,
img, 
strike,
strong,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form, 
label, 
legend,
table, 
caption,
tbody,
tfoot,
thead, 
tr, 
th, 
td,
article, 
aside, 
figure, 
figcaption, 
footer, 
header, 
menu, 
nav, 
section, 
summary {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
}

html, body {
    display: block;
    height: 100%;
}

html:focus-within {
    scroll-behavior: smooth;
}

body {
    min-height: 100vh;
    padding-top: 0.1px;
    text-rendering: optimizeSpeed;
}

ul[role='list'],
ol[role='list'] {
    list-style: none;
}

a:not([class]) {
    text-decoration-skip-ink: auto;
}

b,
strong {
    font-weight: bold;
}
